import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../main.vue'
import login from '../views/Login/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
	 meta: { requiresAuth: true },
    children: [
		 {
		   path: '',
		   name: 'quote',
		   component: () => import('@/views/index/quote')
		 },
		 {
		   path: '/quote',
		   name: 'quote',
		   component: () => import('@/views/index/quote')
		 },		 
		{
		  path: '/product',
		  name: 'product',
		  component: () => import('@/views/index/product')
		},
		{
		  path: '/customer_quote',
		  name: 'customer_quote',
		  component: () => import('@/views/index/customer_quote')
		},
		{
		  path: '/shopping_cart',
		  name: 'shopping_cart',
		  component: () => import('@/views/index/shopping_cart')
		},
		{
		  path: '/unsubmitted',
		  name: 'unsubmitted',
		  component: () => import('@/views/index/unsubmitted')
		},
		{
		  path: '/unsubmitted_details',
		  name: 'unsubmitted_details',
		  component: () => import('@/views/index/unsubmitted_details')
		},
		{
		  path: '/unsubmitted_details_checkout',
		  name: 'unsubmitted_details_checkout',
		  component: () => import('@/views/index/unsubmitted_details_checkout')
		},
		{
		  path: '/shopping_cart_checkout',
		  name: 'shopping_cart_checkout',
		  component: () => import('@/views/index/shopping_cart_checkout')
		},
		{
		  path: '/order',
		  name: 'order',
		  component: () => import('@/views/index/order')
		},
		{
		  path: '/sub_agent_orders',
		  name: 'sub_agent_orders',
		  component: () => import('@/views/index/sub_agent_orders')
		},			
		{
		  path: '/personal_info',
		  name: 'personal_info',
		  component: () => import('@/views/index/personal_info')
		},
		{
		  path: '/address',
		  name: 'address',
		  component: () => import('@/views/index/address')
		},
		{
		  path: '/orderDetail',
		  name: 'orderDetail',
		  component: () => import('@/views/index/order_detail')
		},
		{
		  path: '/learning_center',
		  name: 'learning_center',
		  component: () => import('@/views/index/learning_center')
		},
		{
		  path: '/contact',
		  name: 'contact',
		  component: () => import('@/views/index/contact')
		}
		
		
    ]
  },
  
 {
	path: '/login',
	name: 'login',
	component: login,
	meta: { requiresAuth: false } // 登录页不需要验证
 },
 
 {
 	path: '/create',
 	name: 'create',
 	component: () => import('@/views/Login/create'),
 	meta: { requiresAuth: false }
 },
 
 {
 	path: '/changePassword',
 	name: 'changePassword',
 	component: () => import('@/views/Login/changePassword'),
 	meta: { requiresAuth: false }
 },
 
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem('user') && localStorage.getItem('userExpiration');

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});



/* 路由发生变化请求检查授权 */
// router.beforeEach(async (to, from, next) => {

// const user = getGlobalData("user");
// const {notRequiredValid, needBingTel} = to.meta;
//
// if (notRequiredValid) {
//     return next();
// }

//如果用户已授权信息则允许通过逻辑路由
// if (needBingTel && !user.userTel) {
//     try {
//         await showDialog("请先授权", "提示", false);
//         if (to.path === "/home" || to.path === "/") {
//             return next()
//         }
//         next("/login")
//     } catch (e) {
//         next("/login")
//     }
//
// } else {
//     //console.log("needBingTel", needBingTel)
//     next()
// }

// });
export default router
