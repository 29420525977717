import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
     token: '',
	 checkoutData: [],
	 orderData:[],
	 personName: '',  
	 personAddress: '',
	 extraInfo:{},
	 markdown: '',
	 unsubmittedJob:{},
	 cartId:0,
  },
  mutations: {
    setToken(state, token) {
        state.token = token;
    },
	 setCheckoutData(state, data) {
		 state.checkoutData = data;
	 },
	 setOrderData(state, data) {
	 	 state.orderData = data;
	 },
	 setPersonName(state, name) {  
	    state.personName = name;
	 },
	 setPersonAddress(state, address) {
	    state.personAddress = address;
	 },
	 setExtraInfo(state, info) {
	    state.extraInfo = info;
	 },
	 setMarkdown(state, markdown) { // Add this mutation
	    state.markdown = markdown;
	 },
	 setUnsubmittedJob(state,job){
		 state.unsubmittedJob = job;
	 },
	 setCartId(state,job){
		 state.cartId = job;
	 }
	 
  },
  actions: {

  },
  modules: {
    // configData
  }
})
