<template>
  <div id="app">
    <router-view />
    <loading></loading>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css';
// import './assets/css/public.css';
// import './assets/css/elecustom.css';
// import './assets/css/page.css';
// import './assets/css/iconfont.css';
import loading from '@/components/loading';
import { mapMutations } from 'vuex';

export default {
  name: 'App',
  created() {

  },
  mounted() {
    window.onbeforeunload = function (e) {
      // var storage = window.localStorage;
      // storage.clear()
      var sessionStore = window.sessionStorage;
      sessionStore.removeItem('topMenu');
      sessionStore.removeItem('leftMenu');
    };
  },
  data() {
    return {};
  },
  components: {
    loading
  },
  watch: {},
  methods: {

  }
};
</script>
