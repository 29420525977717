<template>
  <div class="markbox first-loading-wrp" v-show="loading" style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="loading-wrp">
      <span class="dot dot-spin">
        <i></i>
        <i></i>
       <i></i>
      </span>
    </div>
  </div>
</template>

<script>
import '../../public/static/css/loading.css';
export default {
  name: 'loading',
  data() {
    return {
      loading: false
    };
  },
  created() {
    var that = this;
    this.bus.$on('loading', function (data) {
      that.loading = !!data;
    });
  }
};
</script>


<style scoped lang="scss">
.loading-wrp {
  z-index: 998!important;
  background-color: rgba(255, 255, 255);
  position: absolute;
  top: 65px;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
